<template>
  <div class="units-allocation">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="30vh"
               class="allocation-dialog">
      <div slot="title"
           class="units-allocation-form__header">
        <span>分配</span>
      </div>
      <el-form ref="allocationForm"
               :model="unitForm"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="units-allocation-form__body">
          <el-form-item label="记账单位"
                        prop="id">
            <el-select v-model="unitForm.id"
                       placeholder="请选择记账单位"
                       filterable
                       :loading="loading"
                       clearable
                       @change="optionChange">
              <!-- ##### 勿删 ##### -->
              <!-- 远程搜索 预留 -->
              <!-- <el-select v-model="unitForm.id"
                       placeholder="请选择记账单位"
                       filterable
                       remote
                       :remote-method="remoteMethod"
                       :loading="loading"
                       clearable
                       @change="optionChange"> -->
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记账单位数量"
                        prop="quantity">
            <el-input v-model="unitForm.quantity"
                      placeholder="请输入记账单位数量"
                      maxlength="10"
                      clearable
                      @input="limitPositiveInteger"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="units-allocation-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('allocationForm')">确定</el-button>
      </div>
      <div class="units-allocation-form__info">
        <span class="info">当前余额：<span class="balance">{{unitForm.balance}}</span></span>
        <span class="info">剩余合计：<span>{{unitForm.quantity ? unitForm.balance - unitForm.quantity : ''}}</span></span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'

export default {
  data() {
    // 校验记账单位是否录入正确
    let validateUnitName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择记账单位'))
      } else {
        return callback()
      }
    }
    // 校验记账单位数量是否录入正确
    // TODO:记账单位的分配不能超过剩余的记账单位
    let validateUnitQuantity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入记账单位数量'))
      } else if (this.unitForm.balance - this.unitForm.quantity < 0) {
        return callback(new Error('记账单位余额不足'))
      } else {
        return callback()
      }
    }
    return {
      unitForm: {
        id: '',
        name: '',
        quantity: '',
        balance: ''
      },
      // 校验规则
      rules: {
        id: [
          { validator: validateUnitName, trigger: 'change' }
        ],
        quantity: [
          { validator: validateUnitQuantity, trigger: ['blur', 'change'] }
        ]
      },
      // 记账单位
      options: [], // 远程搜索的记账单位结果
      record: [],
      dialogWidth: '0', // 窗口宽度
      loading: false,
      visible: false
    }
  },
  mounted() {
    this.clearForm()
    this.visible = true
    this.setDialogWidth()
    this.getUnitOptions()
    // 监听浏览器窗口大小变化，用于分配记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.setDialogWidth()
      }
    }
  },
  methods: {
    // 清楚窗口中已输入的内容
    clearForm() {
      this.unitForm.quantity = ''
      this.unitForm.id = ''
      this.unitForm.balance = ''
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let unitData = deepClone(this.unitForm)
          this.$emit('submit', unitData)
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '440px'
      }
    },
    // 控制记账单位只能输入正整数
    limitPositiveInteger() {
      if (this.unitForm.quantity.length === 1) {
        this.unitForm.quantity = this.unitForm.quantity.replace(/[^1-9]/g, '')
      } else {
        this.unitForm.quantity = this.unitForm.quantity.replace(/\D/g, '')
      }
    },
    optionChange() {
      if (this.unitForm.id) {
        let res = this.records.filter(item => item.id === this.unitForm.id)
        this.unitForm.balance = res[0].remainderQuantity
      } else {
        this.unitForm.quantity = ''
        this.unitForm.balance = ''
      }
    },
    getUnitOptions(search = '') {
      this.$http.unit.getUnitList({
        size: 500,
        page: 1,
        name: search,
        statusList: ['未使用', '使用中']
      }).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.records = res.data.data.records
          this.options = []
          for (let r of this.records) {
            this.options.push({ value: r.id, label: r.name })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
    // ##### 勿删 #####
    // 远程搜索记账单位
    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true
    //     // 获取后端返回记账单位记录
    //     this.getUnitOptions(query)
    //   }
    // }
  }
}
</script>

<style lang="scss">
.allocation-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 103px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.units-allocation {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
    &__info {
      position: absolute;
      top: 230px;
      left: 161px;
      user-select: none;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-extra-small;
        margin-right: 40px;
        color: #b3b4ba;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 10px;
    }
  }
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 134px;
  }
}

@media screen and (max-width: 375px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 208px;
        }
      }
    }
  }
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 127px;
  }
}

@media screen and (max-width: 320px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 195px;
        }
      }
    }
  }
}
</style>
