<template>
  <div class="units-allocation">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="30vh"
               class="allocation-dialog-1">
      <div slot="title"
           class="units-allocation-form__header">
        <span>发券</span>
      </div>
      <el-form ref="allocationForm"
               :model="form"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="units-allocation-form__body">
          <el-form-item label="票券名称"
                        prop="type">
            <el-select v-model="form.type"
                       placeholder="请选择票券名称"
                       filterable
                       value-key="name"
                        @change="optionChange"
                       clearable>
                 <el-option v-for="(option,index) in optionsResult"
                         :label="option.name"
                         :value="option"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效期"
                        prop="expiry">
            <el-input v-model="form.expiry"
                   disabled
                      placeholder="票券有效期"
                    > </el-input>
          </el-form-item>
          <el-form-item label="已选用户"
                        prop="users">
            <el-input v-model="userList.length"
                      placeholder="已选用户数量"
                      disabled
                    > <template slot="append" ><span class="default-text">个</span></template></el-input>
          </el-form-item>
           <el-form-item label="分配数量"
                        prop="number">
            <el-input v-model="form.number"
                      placeholder="请输入分配数量"
                      maxlength="3"
                      clearable
                      @input="limitPositiveInteger" >
                      <template slot="append" ><span class="default-text">张</span></template></el-input>
          </el-form-item>
          <el-form-item>
            <div class="units-allocation-form__info">
              <span class="info">合计：<span class="balance">{{userList.length*form.number}}</span></span>
              <span class="info">当前余额：<span>{{form.total && form.total - userList.length*form.number}}</span></span>
            </div>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer"
           class="units-allocation-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('allocationForm')">确定</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'
import timeFormat from '@/utils/timeFormat.js'
export default {
  data() {
    // 校验记账单位是否录入正确
    // 校验记账单位数量是否录入正确
    // TODO:记账单位的分配不能超过剩余的记账单位
    let validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入分配数量'))
      } else {
        if (this.form.number * this.userList.length > this.form.total) {
          return callback(new Error('票券数量不足'))
        } else {
          return callback()
        }
      }
    }
    return {
      form: {
        type: '',
        expiry: '',
        number: null,
        total: ''
      },
      // 校验规则
      rules: {
        type: [ { validator: (rule, val, callback) => {
          if (val) {
            callback()
          } else {
            callback(new Error('请选择票券'))
          }
        } }],
        number: [
          { validator: validateNumber }
        ]
      },
      optionsResult: [], // 远程搜索的记账单位结果
      dialogWidth: '0', // 窗口宽度
      loading: false,
      visible: false
      // options: []
    }
  },
  props: {
    // 是否显示分配记账单位窗口
    // 当前行信息
    rowData: {
      type: Object,
      default: () => { }
    },
    userList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.visible = true
    this.setDialogWidth()
    this.getList()
    this.setDialogWidth()
    // 监听浏览器窗口大小变化，用于分配记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.showAllocation) {
        return this.setDialogWidth()
      }
    }, false)
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', () => {
      if (this.showAllocation) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    showAllocation() {
      if (this.showAllocation) {
        this.setDialogWidth()
      }
    },
    number: function() {
      this.checkAmount()
    }
  },
  methods: {
    checkAmount() {
      if (this.form.number * this.userList.length > this.form.total) {
        this.form.number = Math.floor(this.form.total / this.userList.length)
        if (this.form.number === 0) {
          this.form.number = ''
        }
      }
    },
    // 清楚窗口中已输入的内容
    clearForm() {
      this.form.name = ''
      this.form.number = null
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
      this.clearForm()
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let unitData = deepClone(this.form)
          this.$emit('submit', unitData)
          this.handleCancel()
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '440px'
      }
    },
    // 控制记账单位只能输入正整数
    limitPositiveInteger() {
      if (this.form.number.length === 1) {
        this.form.number = this.form.number.replace(/[^1-9]/g, '')
      } else {
        this.form.number = this.form.number.replace(/\D/g, '')
      }
    },
    // 远程搜索记账单位
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        // 获取后端返回记账单位记录
      } else {
        this.optionsResult = []
      }
    },
    getList() {
      this.$http.ticket.getTicketList({
        'ticketName': '',
        'statusList': ['未发放', '发放中'],
        'creatorList': [],
        'page': 1,
        'size': 1000,
        'onlyOwn': true
      }).then(res => {
        if (res.data.code === 0) {
          this.optionsResult = res.data.data.records.filter(item => {
            if (item.type !== '5') {
              return item
            }
          })
        }
      })
    },
    optionChange (data) {
      console.log(data)
      if (data) {
        this.form.total = data.myRemainderQuantity
        this.form.expiry = timeFormat(data.beginTime, 'YYYY.MM.DD') + ' - ' + timeFormat(data.endTime, 'YYYY.MM.DD')
      } else {
        this.form.total = ''
        this.form.expiry = ''
      }

      // 获取选择的记账单位的信息(名称，id，有效期)
      // if (this.form.type) {
      //   let res = this.records.filter(item => item.accountingId === this.form.type)
      //   this.unit.id = this.form.type
      //   this.unit.balance = res[0].remainderQuantity
      //
      //   this.checkAmount()
      // } else {
      //   this.unit.id = ''
      //   this.unit.expiry = ''
      //   this.unit.balance = ''
      //   this.form.amount = ''
      //   this.form.contain = ''
      // }
    }
  }
}
</script>

<style lang="scss">
.allocation-dialog-1{
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
       padding: 49px 40px 49px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.units-allocation {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }

    &__info {
      position: relative;
      top:unset;
      left:unset;
      user-select: none;
      padding: 10px 0;
      color:$--color-text-primary;
      display: flex;
      align-items: center;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-base;
        margin-right: 33px;
        color: $--color-text-primary;
        flex-grow: 1.0;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 10px;
    }
  }
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 134px;
  }
}

@media screen and (max-width: 375px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 208px;
        }
      }
    }
  }
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 127px;
  }
}

@media screen and (max-width: 320px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 195px;
        }
      }
    }
  }
}
.default-text {
  color:#222b45
}
</style>
