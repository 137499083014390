<template>
  <div class="user-management position">
    <div class="title">B端用户管理</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   enableOperatorSlot
                   operatorWidth="200px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   selectCol
                   :clearSelectionFlag="clearSelectionFlag"
                   @selectChange="getSelectChange"
                   @handlePageJump="handlePageJump">
      <template #topControl>
        <div class="top-control clearfix">

          <div class="top-control-add">
            <el-button type="primary"
                       @click="showMerchantAddition">新增</el-button>
          </div>
          <div class="top-control-add">
            <el-button type="primary"
                       @click="showModal(null,'showGroup')">一键发券</el-button>
          </div>
          <div class="top-control-add">
            <el-button type="primary"
                       @click="downLoad">下载发券模板</el-button>
          </div>

          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索商家名称"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleRefresh"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
      </template>

      <template #address="{row}">
        <template v-if="row.address">{{row.address}}</template>
        <template v-else>-</template>
      </template>
      <template #createTime="{row}">
        <template v-if="row.createTime">{{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
        <template v-else>-</template>
      </template>

      <template #operatorCol="{row}">
        <el-button class="operator-btn ym-icon-bianji"
                   @click.stop="showAllocationUnit(row)">分配记账单位</el-button>
        <!-- <el-button class="operator-btn"
                   @click.stop="showModal(row, 'showSingle')"> <i class="el-icon-c-scale-to-original">发券</i></el-button> -->

        <!-- 测试用 -->
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showChainInfo(row)">账户</el-button>
        <!-- 测试用 -->

      </template>
    </ym-table-list>
    <!-- 发券按钮自适应 -->
    <el-button type="primary" class="send-ticket-btn"  @click="showModal(null, 'showSingle')"><i class="el-icon el-icon-s-ticket" ></i>发券</el-button>
    <merchant-addition v-if="showAddition"
                       @hide="hiddenModal('showAddition')"
                       @submit="addMerchant"></merchant-addition>
    <units-allocation v-if="showAllocation"
                      @hide="hiddenModal('showAllocation')"
                      @submit="allocateUnit"></units-allocation>
    <send-single v-if="showSingle"
                :userList="selections"
                 @hide="hiddenModal('showSingle')"
                 @submit="submitSend"></send-single>
    <send-group v-if="showGroup"
                :rowData="rowData"
                @submit="submitGroup"
                @hide="hiddenModal('showGroup')"></send-group>
    <send-progress v-if="showProgress"
                   :flagId="flagId"
                   @hide="hiddenModal('showProgress')"></send-progress>

    <!-- 测试用 -->
    <chain-info v-if="showInfo"
                :hashInfo="hashInfo"
                @CloseChainInfo="closeChainInfo">
      <template #hashTitle>
        <span>账户</span>
      </template>
      <template #hashInfo>
        <div>流通账户：{{hashInfo.currentAccountHash}}</div>
        <div>已核销账户：{{hashInfo.verificationAccountHash}}</div>
      </template>
    </chain-info>
    <!-- 测试用 -->

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import MerchantAddition from './merchantAddition/index.vue'
import unitsAllocation from './unitsAllocation/index.vue'
import sendSingle from './sendTicket/sendSingle.vue'
import sendGroup from './sendTicket/sendGroup.vue'
import sendProgress from './sendTicket/sendProgress.vue'

import { fileDownload } from '@/utils/fileDownload.js'

// 测试用
import ChainInfo from '../chainInfo/index.vue'
import chainInfoMixin from '../chainInfo/chainInfo.js'
// 测试用

export default {

  // 测试用
  mixins: [chainInfoMixin],
  // 测试用

  components: {
    YmTableList,
    MerchantAddition,
    unitsAllocation,
    sendSingle,
    sendGroup,
    sendProgress,
    ChainInfo
  },
  data() {
    return {

      // 列表数据项
      data: [],
      // 列表表头配置项
      columns: [{
        label: '商家名称',
        prop: 'name',
        align: 'center'
      },
      {
        label: '联系人',
        prop: 'contact',
        align: 'center',
        width: '120px'
      },
      {
        label: '联系电话',
        prop: 'phone',
        align: 'center'
      },
      {
        label: '地址',
        prop: 'address',
        align: 'center',
        width: '160px',
        enableColSlot: true
      },
      // {
      //   label: '记账单位情况',
      //   prop: 'unitsStatistics',
      //   align: 'center'
      // },
      {
        label: '创建时间',
        prop: 'createTime',
        align: 'center',
        width: '160px',
        enableColSlot: true
      }],
      pageLayout: 'prev, pager, next, jumper',
      page: 1,
      size: 10,
      ascend: false,
      total: 10, // 数据总数
      search: '', // 搜索内容
      showAddition: false, // 控制新增商户的窗口显示
      showAllocation: false, // 控制分配记账单位的窗口显示
      showSingle: false,
      showGroup: false,
      rowData: {}, // 分配记账单位的当前行数据
      excelUrl: window.BASE_URL + '/static/file/ae90f62e05df207e5908d2a790436e03.xlsx',
      timer: null,
      showProgress: false,
      selections: [],
      clearSelectionFlag: false,
      flagId: '' // 一键导入信息 标志
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索包含对应的商家名称/联系人信息的数据
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 显示新增商家窗口
    showMerchantAddition() {
      this.showAddition = true
      this.$bus.$emit('blurScreen')
    },
    // 销毁新增商家窗口
    hideMerchantAddition() {
      this.$bus.$emit('clearBlur')
      this.showAddition = false
    },
    // 校验规则无误后，添加商户账号
    addMerchant(form) {
      console.log(form)
      // this.hideMerchantAddition()
      this.$http.account.addAccount({
        'roleIds': form.role * 1 === 1 ? ['2'] : ['2'],
        'account': form.account,
        'password': form.accountPwd,
        'phone': form.contactNumber,
        'name': form.merchantName,
        'contact': form.contactName
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.hideMerchantAddition()
          this.handleRefresh()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 分配记账单位
    showAllocationUnit(row) {
      this.showAllocation = true
      this.$bus.$emit('blurScreen')
      this.rowData = row
    },
    // 销毁分配记账单位窗口
    hideUnitsAllocation() {
      this.$bus.$emit('clearBlur')
      this.showAllocation = false
    },
    // 处理记账单位的分配
    allocateUnit(unit) {
      this.$http.unit.allocateUnit({
        accountingId: unit.id,
        userId: this.rowData.id,
        quantity: unit.quantity
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
      this.hideUnitsAllocation()
    },
    // 点击刷新后，刷新搜索条件
    handleRefresh() {
      this.search = ''
      this.handleSearch()
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    getList() {
      this.$http.account.getAccountList({
        page: this.page,
        size: this.size,
        keywords: this.search,
        ascend: this.ascend
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        }
      })
    },
    downLoad() {
      fileDownload(this.excelUrl, '发券模板', 'xlsx')
    },
    sendTicket() {

    },
    hiddenModal(type) {
      console.log('come')
      this.$bus.$emit('clearBlur')
      this[type] = false
      this.getList()
    },
    submitSend(form, rowData) {
      this.clearSelectionFlag = true
      const userList = this.selections.map(item => item.id)
      this.$http.ticket.platSendTicket({
        ticketId: form.type.id,
        userIdList: userList,
        quantity: form.number * 1
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    submitGroup(form, rowData) {
      this.$http.ticket.userTicketsSend({
        ticketId: form.type,
        quantity: form.number,
        excelFileUrl: window.BASE_URL + form.file
      }).then(res => {
        if (res.data.code === 0) {
          this.hiddenModal('showGroup')
          this.flagId = res.data.data
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.showProgress = true
          }, 200)
        }
      })
    },
    showModal(row, type) {
      if (type === 'showSingle') {
        if (this.selections.length <= 0) {
          this.$message({
            type: 'warning',
            message: '请至少选择一个发送对象'
          })
          return false
        }
        this.clearSelectionFlag = false // 控制清除select选项
      }
      this.$bus.$emit('blurScreen')
      this.rowData = row
      this[type] = true
      console.log(type)
    },
    getSelectChange (data) {
      this.selections = data
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss">
.user-management {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
}
.position{
  position: relative;
}
.send-ticket-btn{
  position: absolute;
  bottom:14px;
  left:23px;
}
</style>
