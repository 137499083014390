<template>
  <div class="merchant-addition">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="20vh"
               class="addition-dialog">
      <div slot="title"
           class="merchant-addition-form__header">
        <span>新增</span>
      </div>
      <el-form ref="additionForm"
               :model="merchantForm"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="merchant-addition-form__body">
          <el-form-item class="role"
                        label="角色类型"
                        prop="role">
            <el-radio-group v-model="merchantForm.role">
              <el-radio-button label="1">商家</el-radio-button>
              <!-- <el-radio-button label="2">备用</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商家名称"
                        prop="merchantName">
            <el-input v-model="merchantForm.merchantName"
                      placeholder="请输入商家名称"
                      maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="联系人"
                        prop="contactName">
            <el-input v-model="merchantForm.contactName"
                      placeholder="请输入联系人"
                      maxlength="10"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="联系电话"
                        prop="contactNumber">
            <el-input v-model="merchantForm.contactNumber"
                      placeholder="请输入联系电话"
                      maxlength="11"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="账号名称"
                        prop="account">
            <el-input v-model="merchantForm.account"
                      placeholder="请输入账号名称"
                      maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="初始密码"
                        prop="accountPwd">
            <el-input v-model="merchantForm.accountPwd"
                      placeholder="请输入初始密码"
                      maxlength="16"
                      type="password"
                      clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="merchant-addition-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('additionForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'

export default {
  data() {
    // 校验商家名称
    let validateMerchantName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入商家名称'))
      } else {
        return callback()
      }
    }
    // 校验联系人
    let validateContactName = (rule, value, callback) => {
      let regExp = /^[\u4e00-\u9fa5a-zA-Z]+$/ // 只包含中文和英文
      if (!value) {
        return callback(new Error('请输入联系人'))
      } else if (!regExp.test(value)) {
        return callback(new Error('格式错误，只能输入中文和英文'))
      } else {
        return callback()
      }
    }
    // 校验联系电话
    let validateContactNumber = (rule, value, callback) => {
      let regExp = /^1[3-9]\d{9}$/
      if (!value) {
        return callback(new Error('请输入联系电话'))
      } else if (value.length < 11) {
        return callback(new Error('联系电话不少于11位'))
      } else if (!regExp.test(value)) {
        return callback(new Error('格式错误，请输入正确的联系电话'))
      } else {
        return callback()
      }
    }
    // 校验账号名称
    let validateAccount = (rule, value, callback) => {
      let regExp = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,20}$/
      if (!value) {
        return callback(new Error('请输入账号名称'))
      } else if (!regExp.test(value)) {
        return callback(new Error('格式错误，只能输入中文、英文和数字'))
      } else {
        return callback()
      }
    }
    // 校验密码
    let validatorAccountPwd = (rule, value, callback) => {
      let regExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/
      if (!value) {
        return callback(new Error('请输入初始密码'))
      } else if (value.length < 6) {
        return callback(new Error('密码不能少于6位'))
      } else if (!regExp.test(value)) {
        return callback(new Error('格式错误，只能输入英文和数字的组合'))
      } else {
        return callback()
      }
    }
    return {
      merchantForm: {
        role: '1',
        merchantName: '',
        contactName: '',
        contactNumber: '',
        account: '',
        accountPwd: ''
      },
      // 校验规则
      rules: {
        merchantName: [
          { validator: validateMerchantName, trigger: 'blur' }
        ],
        contactName: [
          { validator: validateContactName, trigger: 'blur' }
        ],
        contactNumber: [
          { validator: validateContactNumber, trigger: 'blur' }
        ],
        account: [
          { validator: validateAccount, trigger: 'blur' }
        ],
        accountPwd: [
          { validator: validatorAccountPwd, trigger: 'blur' }
        ]
      },
      dialogWidth: '0', // 新增商家窗口宽度
      visible: false
    }
  },
  props: {

  },
  mounted() {
    this.clearForm()
    this.visible = true
    this.setDialogWidth()
    // 监听浏览器窗口大小变化，用于新增商家窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.setDialogWidth()
      }
    }
  },
  methods: {
    // 清空窗口中的所有已输入内容
    clearForm() {
      this.merchantForm.role = '1'
      this.merchantForm.merchantName = ''
      this.merchantForm.contactName = ''
      this.merchantForm.contactNumber = ''
      this.merchantForm.account = ''
      this.merchantForm.accountPwd = ''
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = deepClone(this.merchantForm)
          this.$emit('submit', formData)
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '440px'
      }
    }
  }
}
</script>

<style lang="scss">
.addition-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 41px 43px 41px 61px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.merchant-addition {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .role {
        .el-radio-button {
          user-select: none;
          border-radius: 4px;
          overflow: hidden;
          & + .el-radio-button {
            margin-left: 10px;
          }
        }
      }
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .addition-dialog {
    .el-dialog__body {
      padding: 41px 10px;
    }
  }
}

@media screen and (max-width: 375px) {
  .merchant-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .addition-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
}

@media screen and (max-width: 320px) {
  .merchant-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 195px;
        }
      }
    }
  }
}
</style>
